import React, {useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ImgProfile from "../../imgs/mlemtest.jpg";
import {Card, CardContent} from "@material-ui/core";
import { Comment, Form } from 'semantic-ui-react';
import SendIcon from '@material-ui/icons/Send';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import BookmarkBorderSharpIcon from '@material-ui/icons/BookmarkBorderSharp';
import SwipeableViews from 'react-swipeable-views';
import SvgIcon from '@material-ui/core/SvgIcon';
import ReplyIcon from '@material-ui/icons/Reply';
import PersonIcon from '@material-ui/icons/Person';
import {useHistory} from 'react-router-dom';
import Hidden from "@material-ui/core/Hidden";
import {useParams} from 'react-router-dom';
import {getTopicById} from "../../queries/queries";
import {useQuery} from "@apollo/react-hooks";
import LoadingComponent from "../styleComponents/LoadingComponent";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        padding: theme.spacing(0, 3),
    },

    paper: {
        maxWidth: 400,
        padding: theme.spacing(1),
        boxShadow: 'none',
        display: "flex"
    },

    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },

    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },

    margin: {
        margin: theme.spacing(1),

    },

    cover:{
        width: '100%',
        maxHeight: '70%',
        borderRadius: '6px',
    },

    card: {
        boxShadow: 'none',
        border: 'none',
    },

    comment: {
        paddingBottom: '1rem',
    },
}));
const styles = {
    slide: {
        padding: 1,
        minHeight: 100,
        color: '#fff',
    },
};

function HomeIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        </SvgIcon>
    );
}

function Discussion(props) {
    const classes = useStyles();
    const history = useHistory();
    // const history = useHistory();
    const [content, setContent] = useState(0);
    const [profileImgToShow, setProfileImgToShow] = useState(ImgProfile);
    const [topicImgToShow, setTopicImgToShow] = useState(ImgProfile);
    const {id} = useParams();

    const {loading, error, data} = useQuery(getTopicById, {
        variables: {
            id: id
        }
    });

    const displayDiscussion = () => {
        if (loading) return <LoadingComponent/>;
        setContent(data.getTopicById);
    };

    const profileImg = () => {
        if (loading) return <LoadingComponent/>;
        if (data.getTopicById.user[0].metaImage) {
            setProfileImgToShow(data.getTopicById.user[0].metaImage);
        } else {
            setProfileImgToShow(ImgProfile);
        }
    };

    const topicImg = () => {
        if (loading) return <LoadingComponent/>;
        setTopicImgToShow(`https://source.unsplash.com/random`);
        // if (data.getTopicById.metaImage) {
        //     setTopicImgToShow(data.getTopicById.metaImage);
        // } else {
        //     setTopicImgToShow(ImgProfile);
        // }
    };

    useEffect(() => {
        displayDiscussion();
        profileImg();
        topicImg();
    }, [loading]);

    if (loading) return <LoadingComponent/>;
    return (
        <Grid className={classes.root}>
            <Grid>
                <Card className={classes.card} variant="outlined">
                    {content &&
                        <CardContent>
                            <Typography variant="h5" component="h2">
                                {content.title}
                            </Typography>
                            <Grid container className={classes.paper}>
                                <Grid item>
                                    <Avatar src={profileImgToShow} className={classes.small}></Avatar>
                                </Grid>
                                <Grid item style={{marginLeft: 4}}>
                                    <Typography variant="subtitle1" noWrap>{content.user[0].firstName} {content.user[0].lastName}</Typography>
                                </Grid>
                            </Grid>
                            <Hidden smDown>
                                <img alt="profile" className={classes.cover} src={topicImgToShow} style={{maxWidth:"40%", maxHeight:"40%"}}/>
                            </Hidden>
                            <Hidden mdUp>
                                <img alt="profile" className={classes.cover} src={topicImgToShow} style={{maxWidth:"85%", maxHeight:"85%"}}/>
                            </Hidden>

                            <Grid container spacing={1}>
                                <Grid>
                                    <Button style={{color: '#787777'}}>
                                        <ThumbUpAltOutlinedIcon />{content.upvotes}
                                    </Button>
                                </Grid>
                                <Grid>
                                    <Button style={{color: '#787777'}}>
                                        <ExitToAppRoundedIcon />{content.givenBadges.length}
                                    </Button>
                                </Grid>
                                <Grid>
                                    <Button style={{color: '#787777'}}>
                                        <ChatOutlinedIcon />{content.nrComments}
                                    </Button>
                                </Grid>

                                <Grid>
                                    <Button style={{color: '#787777'}}>
                                        <FeaturedPlayListOutlinedIcon />
                                    </Button>
                                </Grid>
                                <Grid>
                                    <Button style={{color: '#787777'}}>
                                        <BookmarkBorderSharpIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    }
                </Card>
            </Grid>

            <Comment.Group threaded >
                <SwipeableViews>
                    <div style={styles.slide}>
                        <Comment style={{display:'flex', paddingBottom: '2rem'}}>
                            <Comment.Content>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Typography variant="caption" className="grayTitles"><b>Raquel Silva</b> | há 1 dia</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography className="blackTitles">Lorem ipsum
                                            dolor sit amet, consectetur adipiscing elit.  </Typography>
                                    </Grid>
                                </Grid>
                                <Hidden smDown>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Button style={{color: '#787777'}}>
                                                <ThumbUpAltOutlinedIcon fontSize="small"/>43
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button style={{color: '#787777'}}>
                                                <ExitToAppRoundedIcon fontSize="small"/>324
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button style={{color: '#787777'}}>
                                                <ChatOutlinedIcon fontSize="small"/>157
                                            </Button>
                                        </Grid>
                                        <Grid container>
                                        </Grid>
                                    </Grid>
                                    <Comment.Actions>
                                        <Typography className="redTitles">Reply</Typography>
                                    </Comment.Actions>
                                </Hidden>
                            </Comment.Content>
                            <Hidden mdUp>
                                <div style={{zIndex: 50000,width: "50%"}}>
                                    {/* Div to fill out space to allow swipe*/}
                                </div>
                            </Hidden>
                        </Comment>
                    </div>
                    <Hidden mdUp>
                        <Card style={{position: "relative", top: "16%"}}>
                            <Paper className={classes.paper} style={{display: "flex"}}>
                                <ThumbUpAltOutlinedIcon className="discussionActions"/>
                                <ExitToAppRoundedIcon className="discussionActions"/>
                                <ReplyIcon className="discussionActions"/>
                                <PersonIcon className="discussionActions" onClick={() => {history.push("/profile");}}/>
                            </Paper>
                        </Card>
                    </Hidden>
                </SwipeableViews>
                <Form reply style={{display:'flex', paddingBottom: '2rem' }}>
                    <Form.TextArea style={{borderRadius: '16px', width: '60vw', border: 'none', boxShadow: '0 0 5px lightgray' }} />
                    <Button content='Add Reply' icon='edit'> <SendIcon  /></Button>
                </Form>
            </Comment.Group>
        </Grid>
    )
}

export default (Discussion);
