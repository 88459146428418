import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import publico from '../imgs/publico.png';
import {useHistory} from 'react-router-dom';
import {useCookies} from "react-cookie";
const axios = require('axios');

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const ColorButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText('#D10019'),
        backgroundColor: '#D10019',
        '&:hover': {
            backgroundColor: '#D10019',
        },
    },
}))(Button);

function SignUp() {
    const classes = useStyles();
    const history = useHistory();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cookies] = useCookies(['cookie-name']);

    useEffect(() => {
        if(cookies.token) redirect();
    }, []);

    const redirect = () => {
        return history.push('/login');

    };

    const savePost = () => {
        axios({
            method: 'post',
            url: 'http://54.159.29.22:4000/user',
            auth:{
                username: email,
                password
            },
            data: {
                firstName,
                lastName,
            }
        });
    };


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar variant="square" src={publico} className={classes.avatar}>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Registo
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="fname"
                            name="firstName"
                            variant="outlined"
                            value={firstName}
                            fullWidth
                            id="primeiroNome"
                            label="Primeiro Nome"
                            onChange={event => {
                                setFirstName(event.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            value={lastName}
                            fullWidth
                            id="lastName"
                            label="Último Nome"
                            name="ultimoNome"
                            onChange={event => {
                                setLastName(event.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            value={email}
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            onChange={event => {
                                setEmail(event.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            value={password}
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            onChange={event => {
                                setPassword(event.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
                <ColorButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={(e) => {
                        e.preventDefault();
                        savePost()
                    }}
                >
                    Registar
                </ColorButton>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Link href="/login" variant="body2">
                            Já possui uma conta? Faça login
                        </Link>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}


export default SignUp