import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
    root: {

        padding: '2rem',
    },
    paper: {
        padding: theme.spacing(0.5),
        margin: 'auto',
        maxWidth: 500,
        marginBottom: '1rem',
    },

    grid: {
        flexWrap: 'noWrap',
        margin: 'auto',
        padding: '1rem',
    }

}));

function ProfileFavourites() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container spacing={1} className={classes.grid}>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="caption" color="secondary">
                                    Design
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Artista com "fome" comeu banana de 120 mil dólares
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    123 gostos
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

            <Paper className={classes.paper}>
                <Grid container spacing={1} className={classes.grid}>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="caption" color="secondary">
                                    Design
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Artista com "fome" comeu banana de 120 mil dólares
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    123 gostos
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>

            <Paper className={classes.paper}>
                <Grid container spacing={1} className={classes.grid}>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="caption" color="secondary">
                                    Design
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Artista com "fome" comeu banana de 120 mil dólares
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    123 gostos
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>


        </div>
    );
}

export default ProfileFavourites;