import React, {useState, useEffect} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {useHistory, useLocation} from 'react-router-dom';
import {useQuery} from "@apollo/react-hooks";
import {getCategories} from "../../queries/queries";
import {useCookies} from "react-cookie";
import LoadingComponent from "../styleComponents/LoadingComponent";

function Home() {

    // const [categories] = useState([{name: 'Início'}, {name: 'Desporto'}, {name: 'Música'}, {name: 'Mundo'}, {name: 'Design'}, {name: 'Gastronomia'}, {name: 'Cultura'}, {name: 'Cinema'}, {name: 'Política'}, {name: 'Moda'}, {name: 'Sociedade'}]);
    const [value, setValue] = useState(0);
    const [writeTabs, setWriteTabs] = useState([]);
    const [cookies] = useCookies(['token']);

    const {loading, error, data} = useQuery(getCategories);

    const history = useHistory();
    const location = useLocation();

    const handleChange = (event, value) => {
        setValue(value)
    };


    useEffect(() => {
        verifyValue();
    }, [loading]);

    const verifyValue = () => {
        if (error) return [];
        if (loading) return <LoadingComponent/>;

        let arrayPush = [];
        let verified = false;
        data.getCategories.unshift({_id: 'feed1', name: 'Início', image: null});
        data.getCategories.map((data, key) => {
            if (key === 0) {
                arrayPush.push(<Tab label={data.name} key={key} onClick={() => {
                    history.push(`/`)
                }}/>)
            } else {
                arrayPush.push(
                    <Tab label={data.name} key={key} onClick={() => {
                        history.push(`/category/${data.name}`)
                    }}/>
                );
            }

            if (location.pathname === '/' && !verified) {
                verified = true;
                setValue(key);
            }
            else if (location.pathname.startsWith('/category/') && !verified) {
                let path = '/category/' + data.name;
                if (location.pathname === path) {
                    verified = true;
                    setValue(key);
                }
            }
            return null;
        });
        // categories.map((data, key) => {
        //     if (key === 0) {
        //         arrayPush.push(<Tab label={data.name} key={key} onClick={() => {
        //             history.push(`/`)
        //         }}/>)
        //     }
        //     else {
        //         arrayPush.push(<Tab label={data.name} key={key} onClick={() => {
        //             history.push(`/category/${data.name}`)
        //         }}/>)
        //     }
        //
        //     if (location.pathname === '/' && !verified) {
        //         verified = true;
        //         setValue(key);
        //     }
        //     else if (location.pathname.startsWith('/category/') && !verified) {
        //         let path = '/category/' + data.name;
        //         if (location.pathname === path) {
        //             verified = true;
        //             setValue(key);
        //         }
        //     }
        //     return null;
        // });
        setWriteTabs(arrayPush);
    };

    return (
        <div>
            {(location.pathname.startsWith("/category/") || location.pathname === "/") &&
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
            >
                {writeTabs}
            </Tabs>
            }
        </div>
    )
}

export default Home;