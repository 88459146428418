import React, {useEffect, useState} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import SecondaryMenuBar from "./SecondaryMenuBar";
import BottomMenu from "./BottomMenu";
import Hidden from "@material-ui/core/Hidden/Hidden";

function VerificationMenuBar() {

    const [style, setStyle] = useState('block');

    const history = useHistory();
    const location = useLocation();


    history.listen((location, action) => {
        if ((location.pathname === '/register') || (location.pathname === '/login')) {
            setStyle('none')
        }
        else {
            setStyle('block')
        }
    });

    useEffect(() => {
        if ((location.pathname === '/register') || (location.pathname === '/login')) {
            setStyle('none')
        }
        else {
            setStyle('block')
        }
    }, [location.pathname]);

    return (
        <div>
            <SecondaryMenuBar style={style}/>
            <Hidden mdUp>
                <BottomMenu style={style}/>
            </Hidden>
        </div>
    )

}

export default VerificationMenuBar;