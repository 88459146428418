import React, {useState} from 'react';
import {fade, makeStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: 10,
    },
    friends: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(2),
        },
        paddingTop: 10,
        width: '100%',
    },
    inline: {
        display: 'inline',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade("#E1E4E6", 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 10,
        marginTop: '1rem',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(6),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        paddingLeft: '3rem',
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    paper: {
        padding: theme.spacing(1),
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        marginLeft: '2rem',
        height: '1.8rem',
        verticalAlign: 'middle',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        paddingTop: '1rem',
        fontSize: '17px',
        backgroundColor: '#F4f4f4',
        boxShadow: 'none',
    },
    title: {
        fontSize: '22px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        paddingLeft: '2rem',
    },
}));

function SearchMenu() {

    const [categories] = useState(['Tudo', 'Categorias e Tags', 'Discussões', 'Comunidades', 'Perfis']);
    const [value, setValue] = useState(0);

    const handleChange = (event, value) => {
        setValue(value)
    };

    const classes = useStyles();

    return (
        <div>
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon/>
                </div>
                <InputBase
                    placeholder="Search…"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{'aria-label': 'search'}}
                />
            </div>
            <div className="mainPadding">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {categories.map((category, key) => (
                        <Tab label={category} key={key}/>
                    ))}
                </Tabs>
            </div>
            <div>
                <p className={classes.title}>Recentes </p>
                <React.Fragment>
                    <Grid item xs={11} >
                        <Paper className={classes.paper}>Desporto</Paper>
                    </Grid>
                    <Grid item xs={11} >
                        <Paper className={classes.paper}>Moda</Paper>
                    </Grid>
                    <Grid item xs={11} >
                        <Paper className={classes.paper}>Mundo</Paper>
                    </Grid>
                </React.Fragment>
            </div>
            <div>
                <p className={classes.title}>Sugeridos</p>
                <React.Fragment>
                    <Grid item xs={11}>
                        <Paper className={classes.paper}>Desporto</Paper>
                    </Grid>
                    <Grid item xs={11}>
                        <Paper className={classes.paper}>Moda</Paper>
                    </Grid>
                    <Grid item xs={11}>
                        <Paper className={classes.paper}>Mundo</Paper>
                    </Grid>
                </React.Fragment>
            </div>
        </div>
    )
}

export default (SearchMenu);


