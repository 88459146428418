import React from 'react';
import {Card, CardContent, makeStyles} from "@material-ui/core";
import imgsTestes from "../../imgs/profile.jpg";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Typography from '@material-ui/core/Typography';
import {useHistory} from 'react-router-dom';
import Fab from "@material-ui/core/Fab/Fab";
import AddIcon from '@material-ui/icons/Add';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        overflowY: 'hidden',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    }

}));

function FeedCommunity() {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Grid className={classes.root}>
            <Card className="feedCreateCommunity" onClick={() => {history.push("/newcommunity");}}>
                <CardContent style={{padding: '5px'}}>
                    <AddIcon style={{fontSize: '50px'}}/>
                </CardContent>
            </Card>
            <Card className="feedCommunityCard" onClick={() => {history.push("/community");}}>
                <CardContent className="feedCommunityBottomRectangle">
                    <CardActions className="feedCommunityCardActions">
                        <AvatarGroup className="feedCommunityAvatarGroup">
                            <Avatar src={imgsTestes} className={classes.small}/>
                            <Avatar src={imgsTestes} className={classes.small} />
                            <Avatar src={imgsTestes} className={classes.small} />
                        </AvatarGroup>
                    </CardActions>
                    <Typography className="redTitles communityTitle">
                        Black Mirror Fans
                    </Typography>
                    <Typography className="communityMembers">
                        50 membros
                    </Typography>
                </CardContent>
            </Card>
            <Card className="feedCommunityCard">
                <CardContent className="feedCommunityBottomRectangle">
                    <CardActions className="feedCommunityCardActions">
                        <AvatarGroup className="feedCommunityAvatarGroup">
                            <Avatar src={imgsTestes} className={classes.small}/>
                            <Avatar src={imgsTestes} className={classes.small} />
                            <Avatar src={imgsTestes} className={classes.small} />
                        </AvatarGroup>
                    </CardActions>
                    <Typography className="redTitles communityTitle">
                        Black Mirror Fans
                    </Typography>
                    <Typography className="communityMembers">
                        50 membros
                    </Typography>
                </CardContent>
            </Card>
            <Card className="feedCommunityCard">
                <CardContent className="feedCommunityBottomRectangle">
                    <CardActions className="feedCommunityCardActions">
                        <AvatarGroup className="feedCommunityAvatarGroup">
                            <Avatar src={imgsTestes} className={classes.small}/>
                            <Avatar src={imgsTestes} className={classes.small} />
                            <Avatar src={imgsTestes} className={classes.small} />
                        </AvatarGroup>
                    </CardActions>
                    <Typography className="redTitles communityTitle">
                        Black Mirror Fans
                    </Typography>
                    <Typography className="communityMembers">
                        50 membros
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default FeedCommunity;