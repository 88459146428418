import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import {Card, CardContent, Hidden} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import AvatarGroup from "@material-ui/lab/AvatarGroup/AvatarGroup";
import imgsTestes from "../../imgs/profile.jpg";
import FeedLatest from "../home/FeedLatest";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import {useParams} from 'react-router-dom';
import ApprovalRequests from "../Community/ApprovalRequest";
import {
    getCommunityById,
    checkUserOnCommunity,
    addCommunityMutation,
    addPendingUserOnCommunity, getBooksQuery
} from "../../queries/queries";
import {useMutation, useQuery} from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import TokenDecode from "../../utilComponents/TokenDecode";
import TextField from "@material-ui/core/TextField";
import LoadingComponent from "../styleComponents/LoadingComponent";

const useStyles = makeStyles(theme => ({
    root: {
        padding: '1rem',
    },

    paper: {
        maxWidth: 400,
        padding: theme.spacing(2),
        boxShadow: 'none',
    },

    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },

    margin: {
        margin: theme.spacing(1),

    },

    cover: {
        width: '100%',
        maxHeight: '70%',
        marginTop: '1rem',
        borderRadius: '6px',
    },

    card: {
        boxShadow: 'none',
        border: 'none',
    },

    comment: {
        paddingBottom: '1rem',
    },
}));

const ColorButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText('#D10019'),
        backgroundColor: '#D10019',
        '&:hover': {
            backgroundColor: '#D10019',
        },
    },
}))(Button);

function Community(props) {
    const [value, setValue] = useState(0);
    const [content, setContent] = useState(0);
    const [belongs, setBelongs] = useState(false);
    const [displayEntryMessage, setDisplayEntryMessage] = useState(false);
    const [requestDisabled, setRequestDisabled] = useState(true);
    const [entryMessage, setEntryMessage] = useState('');
    const handleChange = (event, value) => {
        setValue(value)
    };
    const classes = useStyles();
    const {id} = useParams();
    const decoded = TokenDecode();

    const {loading: loading, error, data} = useQuery(getCommunityById, {
        variables: {
            id: id
        }
    });
    const {loading: loadingCheck, error: errorCheck, data: dataCheck} = useQuery(checkUserOnCommunity, {
        variables: {
            userId: decoded._id,
            communityId: id
        }
    });
    const [addPendingUser, {data: dataPendingUser}] = useMutation(addPendingUserOnCommunity);

    const addPending = () => {
        addPendingUser({
            variables: {
                user: decoded._id,
                community: id,
                entryMessage: entryMessage
            }, 
            refetchQueries:[
                {query: checkUserOnCommunity, variables:{
                        userId: decoded._id,
                        communityId: id
                    }}
            ]

        });
    };


    const displayContent = () => {
        if (loading) return <LoadingComponent/>;
        setContent(data.getCommunityById[0]);
    };

    const belongsToCommunity = () => {
        if (loading) return <LoadingComponent/>;
        console.log(dataCheck);
        // if (dataCheck.checkUserOnCommunity.length > 0) {
        //     setBelongs(true);
        // } else {
        //     setBelongs(false);
        // }
    };

    const showEntryMessage = (model) => {
        if (model === true) {
            setDisplayEntryMessage(false);
        } else {
            setDisplayEntryMessage(true);
        }
    };

    useEffect(() => {
        if (entryMessage !== '') {
            setRequestDisabled(false);
        } else {
            setRequestDisabled(true);
        }
    }, [entryMessage]);

    useEffect(() => {
        displayContent();
        belongsToCommunity();
    }, [loading]);

    if (loading || loadingCheck) return <LoadingComponent/>;

    return (
        <Grid className={classes.root}>
            <Card className="communityCard" style={{background: `url('${content.image}') no-repeat`}}>
                <CardContent className="communityBottomRectangle">
                    <CardActions className="communityCardActions">
                        <AvatarGroup className="communityAvatarGroup">
                            <Avatar src={imgsTestes} className={classes.small}/>
                            <Avatar src={imgsTestes} className={classes.small}/>
                            <Avatar src={imgsTestes} className={classes.small}/>
                        </AvatarGroup>
                    </CardActions>
                    <Typography className="communityTitle">
                        {content.name}
                    </Typography>
                    <Typography className="communityMembers">
                        {content.number_members} membros
                    </Typography>
                </CardContent>
            </Card>

            {dataCheck.checkUserOnCommunity.length === 0 ?
                <Grid item xs={12} className={classes.root} style={{textAlign: "center", width: "100%"}}>
                    <ColorButton variant="contained" onClick={() => showEntryMessage(displayEntryMessage)}>
                        Pedir para aderir
                    </ColorButton>
                    {displayEntryMessage &&
                    <Grid container>
                        <Hidden mdUp>
                            <Grid container style={{marginTop: 10}} spacing={2}>
                                <Grid item xs={12} md={6} container justify='center' alignContent='center'>
                                    <TextField
                                        fullWidth
                                        id="entry_message"
                                        name="entry_message"
                                        value={entryMessage}
                                        onChange={(e) => {setEntryMessage(e.target.value)}}
                                        label="Mensagem de entrada"
                                        multiline
                                        variant='outlined'
                                        defaultValue=""
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} container justify='flex-end' alignContent='center'>
                                    <ColorButton variant="contained" disabled={requestDisabled} onClick={() => {addPending()}}>Submeter</ColorButton>
                                </Grid>
                            </Grid>
                        </Hidden>
                        <Hidden smDown>
                            <Grid container style={{marginTop: 10}} spacing={2}>
                                <Grid item xs={2}/>
                                <Grid item xs={12} md={6} container justify='center' alignContent='center'>
                                    <TextField
                                        fullWidth
                                        id="entry_message"
                                        name="entry_message"
                                        value={entryMessage}
                                        onChange={(e) => {setEntryMessage(e.target.value)}}
                                        label="Mensagem de entrada"
                                        multiline
                                        required
                                        variant='outlined'
                                        defaultValue=""
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} container justify='center' alignContent='center'>
                                    <ColorButton variant="contained" disabled={requestDisabled} onClick={() => {addPending()}}>Submeter</ColorButton>
                                </Grid>
                                <Grid item xs={2}/>
                            </Grid>
                        </Hidden>
                    </Grid>
                    }
                </Grid>
                : dataCheck.checkUserOnCommunity[0].status === null ?
                    <Grid container>
                        <Grid className={classes.root} style={{textAlign: "center", width: "100%"}}>
                            <ColorButton variant="contained" disabled={true}>
                                Pedido Pendente
                            </ColorButton>
                        </Grid>
                    </Grid>
                    : dataCheck.checkUserOnCommunity[0].status === true ?
                        <Grid container>
                            <Grid className={classes.root} style={{textAlign: "center", width: "100%"}}>
                                <ColorButton variant="contained" disabled={true}>
                                    Pedido Aceite
                                </ColorButton>
                            </Grid>
                        </Grid>
                        : null}

            <Grid container style={{display: 'block'}}>
                <Grid className={classes.root}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        display='block'
                        /* variant="fullWidth"     */
                    >
                        <Tab label="Feed"/>
                        <Tab label="Pedidos de aprovação"/>
                    </Tabs>
                    {value === 0 && <FeedLatest/>}
                    {value === 1 && <ApprovalRequests/>}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default (Community);
