import React, {useState, useEffect} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import SettingsIcon from '@material-ui/icons/Settings';
import {useLocation, useHistory} from 'react-router-dom';
import {Grid} from "@material-ui/core";
import MenuBar from "./MenuBar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Hidden from "@material-ui/core/Hidden/Hidden";
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';

function SecondaryMenuBar(props) {

    const location = useLocation();
    const history = useHistory();
    const [toDisplay, setToDisplay] = useState('block');
    const useName = () => {

        const name = React.useMemo(() => {
            if (location.pathname.startsWith("/profile")) {
                return "Perfil"
            }
            if (location.pathname.startsWith("/messages")) {
                return "Mensagens"
            }
            if (location.pathname.startsWith("/create")) {
                return "Nova Discussão"
            }
            if (location.pathname.startsWith("/search")) {
                return "Pesquisar"
            }
            if (location.pathname.startsWith("/notifications")) {
                return "Notificações"
            }
            if (location.pathname.startsWith("/discussion")) {
                return "Discussão"
            }
            if (location.pathname.startsWith("/settings")) {
                return "Definições"
            }
            if (location.pathname.startsWith("/community")) {
                return "Comunidade"
            }
            if (location.pathname === ("/newcommunity")) {
                return "Nova Comunidade"
            }

            // return "default"
        }, [location]);
        return name
    };

    useEffect(() => {
        setToDisplay(props.style);
    }, [props.style]);
    const name = useName();

    return (
        <div style={{display: toDisplay}}>
            {(location.pathname === "/" || location.pathname.startsWith("/category")) ? <MenuBar/> :
                <AppBar className="menuColor" position="static" style={{
                    width: '100%',
                    backgroundColor: 'white',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    height: 60,
                    zIndex: 5000,
                    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
                }}>
                    <Toolbar>
                        <Grid container justify="center">
                            <Grid container item xs={12} md={10} sm={10} lg={8} alignItems="center">
                                <Hidden smUp>
                                    <ArrowBackRoundedIcon className="arrowColor" onClick={() => {
                                        history.goBack();
                                    }}/>
                                </Hidden>
                                <Hidden smUp><Typography variant="h6" className="menuTitle">
                                    {name}
                                </Typography>
                                </Hidden>
                                <Hidden smDown><Typography variant="h6" className="menuTitle" onClick={() => {
                                    history.push("/");
                                }}>
                                    ÁGORA
                                </Typography>
                                </Hidden>
                                <Hidden smDown>
                                    <div>
                                        <IconButton color="inherit" onClick={() => {
                                            history.push("/messages");
                                        }}>
                                            <ForumOutlinedIcon/>
                                        </IconButton>
                                        <IconButton color="inherit" onClick={() => {
                                            history.push("/notifications");
                                        }}>
                                            <NotificationsNoneOutlinedIcon/>
                                        </IconButton>
                                    </div>
                                </Hidden>
                                {location.pathname.startsWith("/profile/") &&
                                <IconButton color="inherit" onClick={() => {
                                    history.push("/settings")
                                }}>
                                    <SettingsIcon/>
                                </IconButton>}
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            }
        </div>
    );
}

export default SecondaryMenuBar;