import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import dog from "../../imgs/transferir.jpg";
import CheckIcon from '@material-ui/icons/Check';
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import Paper from "@material-ui/core/Paper/Paper";
import ClearIcon from '@material-ui/icons/Clear';


const useStyles = makeStyles(theme => ({
    root: {
        padding: '2rem',
    },
    paper: {
        padding: '1rem',
        margin: 'auto',
        textAlign: 'center',
    },
    grid: {
        flexWrap: 'noWrap',
        margin: 'auto',
        padding: '1rem',
        display: 'block',
        alignItems: 'center',
        width: '100%'
    },
    margin: {
        margin: theme.spacing(1),
    },

}));


function ApprovalRequests() {

    const classes = useStyles();
    return (


        <Grid container className="p10Top" // onClick={() => {history.push("/messages/details");}}
        >
            <Grid container spacing={1}>
                <Paper className={classes.paper}>
                    <Grid container spacing={1}>
                        <Grid item xs className={classes.grid}>
                            <div style={{marginLeft: '42%'}}>
                                <Avatar alt="Remy Sharp" src={dog}/>
                            </div>
                            <Typography variant="body1" className={'textNameRequests'}>
                                Joana Santos Silva da Silva Silva
                            </Typography>
                            <Fab size="small" color="primary" className={classes.margin}>
                                <CheckIcon/>
                            </Fab>
                            <Fab size="small" color="primary" className={classes.margin}>
                                <ClearIcon/>
                            </Fab>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>

    );
}

export default ApprovalRequests;
