import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import publico from '../imgs/publico.png';
import {useHistory} from 'react-router-dom';
import {useCookies} from 'react-cookie';

const axios = require('axios');
var jwt = require('jsonwebtoken');

const useStyles = makeStyles(theme => ({
    root: {
        height: '80vh',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/random)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const ColorButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText('#D10019'),
        backgroundColor: '#D10019',
        '&:hover': {
            backgroundColor: '#D10019',
        },
    },
}))(Button);

export default function SignInSide() {
    const classes = useStyles();
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cookies, setCookie] = useCookies(['token']);

    useEffect(() => {
        if (cookies.token) {
            if (cookies.token !== "undefined") {
                history.push('/');
            }
        }
    }, []);

    const login = () => {

        axios.post('http://agora.jmfreitas.com/auth', {email, password}, {
            auth: {
                username: email,
                password
            }
        }).then(response => {
            const token = response.headers['x-auth-token'];
            if (token) {
                setCookie('token', token);
                history.push('/');
            }
        });

        // return history.push('/');
    };

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={4} md={7} className={classes.image}/>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar variant="square" src={publico} className={classes.avatar}>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Entrar
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            value={email}
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            onChange={event => {
                                setEmail(event.target.value)
                            }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            value={password}
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            onChange={event => {
                                setPassword(event.target.value)
                            }}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary"/>}
                            label="Manter sessão iniciada"
                        />
                        <ColorButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={(e) => {
                                e.preventDefault();
                                login()
                            }}
                        >
                            Entrar
                        </ColorButton>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    Esqueci-me da password
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/register" variant="body2">
                                    {"Não tem uma conta? Registe-se"}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}