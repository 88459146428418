import React, {useEffect, useRef, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import profileImg from '../../imgs/profile.jpg';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {addCommunityMutation, getCategories} from "../../queries/queries";
import TokenDecode from "../../utilComponents/TokenDecode";
import {Redirect, useHistory} from "react-router-dom";
import LoadingComponent from "../styleComponents/LoadingComponent";
const axios = require('axios');


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        padding: theme.spacing(0, 3),
    },
    paper: {
        maxWidth: 400,
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    margin: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    addTag: {
        backgroundColor: 'white !important',
        border: '1px solid black',
        outlineColor: 'black',
        color: 'black',
        width: theme.spacing(3),
        height: theme.spacing(3),
        margin: '5px',
    },
}));

const ColorButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText('#D10019'),
        backgroundColor: '#D10019',
        '&:hover': {
            backgroundColor: '#D10019',
        },
    },
}))(Button);

function CreateCommunity() {
    const history = useHistory();
    const classes = useStyles();
    const [category, setCategory] = useState([]);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [open, setOpen] = useState(false);
    const [url, setUrl] = useState("");
    const fileInput = useRef(null);

    const decoded = TokenDecode();

    const {loading, error, data} = useQuery(getCategories);
    const [addCommunity, {data: dataCommunity}] = useMutation(addCommunityMutation);

    const handleChange = event => {
        setCategory(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const submitForm = () => {
        let idCategory = '';

        for (let i = 0; i < data.getCategories.length; i++) {
            if (category === data.getCategories[i].name) {
                idCategory = data.getCategories[i]._id
            }
        }

        addCommunity({
            variables: {
                name: title,
                description: body,
                category: idCategory,
                image: url
            },
        });

        if (dataCommunity) {
            history.push(`/community/${dataCommunity.addCommunity._id}`)
        }
        // console.log(dataCommunity.addCommunity._id);
        // return <Redirect to="/discussion/"/>
    };

    function onSubmit(e) {
        e.preventDefault();
        const data = new FormData();
        const imagefile = document.querySelector('#imgFile');
        data.append("type", "topic");
        data.append("imgFile", imagefile.files[0]);
        axios.post('http://agora.jmfreitas.com/upload', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            setUrl(response.data.url);
        });
    }

    function fileNames() {
        const { current } = fileInput;

        if (current && current.files.length > 0) {
            let messages = [];
            for (let file of current.files) {
                messages = messages.concat(<p key={file.name}>{file.name}</p>);
            }
            return messages;
        }
        return null;
    }

    useEffect(e => {
        window.addEventListener("keyup", clickFileInput);
        return () => window.removeEventListener("keyup", clickFileInput);
    });

    function clickFileInput(e) {
        if (fileInput.current.nextSibling.contains(document.activeElement)) {
            // Bind space to trigger clicking of the button when focused
            if (e.keyCode === 32) {
                fileInput.current.click();
            }
        }
    }


    if (loading) return <LoadingComponent/>;
    return (
        <Grid className={classes.root}>
            <Grid>
                <form>
                    <Paper className={classes.paper}>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Avatar src={profileImg}></Avatar>
                            </Grid>
                            <Grid item style={{paddingTop: '1.5vh'}}>
                                <Typography variant="h5" noWrap>{decoded.firstName} {decoded.lastName} </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                        <TextField
                            id="outlined-textarea"
                            placeholder="Título"
                            label="Título"
                            margin="none"
                            variant="outlined"
                            onChange={(e) => {
                                setTitle(e.target.value)
                            }}
                            multiline
                            fullWidth
                            required
                        />
                    </Paper>
                    <Paper className={classes.paper}>
                        <TextField
                            id="outlined-textarea"
                            placeholder="Descrição da categoria"
                            multiline
                            label="Descrição"
                            margin="none"
                            variant="outlined"
                            onChange={(e) => {
                                setBody(e.target.value)
                            }}
                            fullWidth
                            required
                        />
                    </Paper>
                    <Paper className={classes.paper}>
                        <Typography variant="h6">Categoria associada</Typography>
                        <FormControl className={classes.formControl}>
                            <Select
                                labelId="demo-controlled-open-select-label"
                                id="demo-controlled-open-select"
                                open={open}
                                onClose={handleClose}
                                onOpen={handleOpen}
                                value={category}
                                onChange={handleChange}
                                required
                            >
                                {data.getCategories.map((data, key) =>
                                    (<MenuItem value={data.name} key={key}>
                                        {data.name}
                                    </MenuItem>)
                                )}
                            </Select>
                        </FormControl>
                    </Paper>
                    <input
                        id="imgFile"
                        type="file"
                        ref={fileInput}
                        // The onChange should trigger updates whenever
                        // the value changes?
                        // Try to select a file, then try selecting another one.
                        onChange={onSubmit}
                    />
                    {fileNames()}
                    <Paper className={classes.paper}>
                        <ColorButton variant="contained" color="primary" className="redBackgrounds" onClick={() => { submitForm()}}>
                            Criar Comunidade
                        </ColorButton>
                    </Paper>
                </form>
            </Grid>
        </Grid>
    )

}

export default CreateCommunity;