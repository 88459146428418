import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import ChatIcon from '@material-ui/icons/Chat';
import {useHistory} from 'react-router-dom';
import {useQuery} from "@apollo/react-hooks";
import {getTopics} from "../../queries/queries";
import LoadingComponent from "../styleComponents/LoadingComponent";

function FeedLatest(props) {
    const history = useHistory();
    const [content, setContent] = useState([]);
    const {loading, error, data} = useQuery(getTopics);

    const goToDiscussion = (id) => {
        history.push(`/discussion/${id}`)
    };

    const displayContent = () => {
        if (loading) return <LoadingComponent/>;
        setContent(data.getTopics);
    };

    useEffect(() => {
        displayContent();
    }, [loading]);

    if (loading) return <LoadingComponent/>;
    return (
        <Grid container>
            <div style={{paddingRight: '40px',paddingLeft: '20px', overflowY: "hidden", display: "flex"}}>
                {content.map((topic, key) =>
                    (
                        <Card style={{background: `url("https://source.unsplash.com/random")`,
                            minHeight: 200,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center', overflow: "initial", color: "white", marginRight: "10px"}} key={key} onClick={(e) => { e.preventDefault(); goToDiscussion(topic._id)}}>
                            <CardContent>
                                <Typography>{topic.category[0].name}</Typography>
                                    <Typography>{topic.body}</Typography>
                            </CardContent>
                            <CardActions style={{paddingTop: "34%"}}>
                                <Button size="small" color="primary">
                                    <ChatIcon style={{marginRight: 5}}/> {topic.upvotes}
                                </Button>
                                <Button size="small" color="primary">
                                    <ChatIcon style={{marginRight: 5}}/> {topic.givenBadges.length}
                                </Button>
                                <Button size="small" color="primary">
                                    <ChatIcon style={{marginRight: 5}}/> {topic.nrComments ? topic.nrComments : 0}
                                </Button>
                            </CardActions>
                        </Card>
                    )
                )}
            </div>
        </Grid>
    )
}

export default FeedLatest;