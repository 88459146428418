import React from 'react';
import './css/main.min.css';
import ApolloClient from 'apollo-boost';
import MessageList from './components/Messages/MessageList';
import {ApolloProvider} from 'react-apollo'
import Grid from "@material-ui/core/Grid/Grid";
import Home from "./components/home/Home";
import MainScreen from "./components/MainScreen";
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import SignInSide from "./components/SignInSide";
import SignUp from "./components/SignUp";
import {BrowserRouter, Redirect} from "react-router-dom";
import CreateDiscussion from "./components/Discussion/CreateDiscussion";
import Discussion from "./components/Discussion/Discussion";
import SearchMenu from "./components/Search/SearchMenu";
import Notifications from "./components/Notifications/Notifications";
import {useCookies} from "react-cookie";
import VerificationMenuBar from "./components/VerificationMenuBar";
import SettingsScreen from "./components/Settings/SettingsScreen";
import ProfilePage from "./components/Profile/ProfilePage";
import Community from "./components/Community/Community";
import MessageConversation from "./components/Messages/MessageConversation";
import CreateCommunity from "./components/Community/CreateCommunity";

const Switch = require("react-router-dom").Switch;
const Route = require("react-router-dom").Route;

const client = new ApolloClient({
    uri: 'http://agora.jmfreitas.com/graphql'
});

const theme = createMuiTheme({
    overrides: {
        MuiButton: {
            textPrimary: {
                color: '#fff',
            }
        },
    },
});

function App() {

    const [cookies] = useCookies(['token']);

    // const decoded = TokenDecode();

    // console.log(decoded);

    const tokenIsUndefined = () => {
        return cookies.token === "undefined" || !cookies.token;
    };

    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    {(tokenIsUndefined() && window.location.pathname !== "/register") && <Redirect to='/login'/>}
                    <Grid container justify="center">
                        <VerificationMenuBar/>
                        <Grid item xs={12} md={10} sm={10} lg={8}>
                            <div id="main" style={{marginBottom: 60, marginTop: 80}}>
                                <Home/>
                                <Switch>
                                    <Route exact path='/' component={MainScreen}/>
                                    <Route exact path='/search' component={SearchMenu}/>
                                    <Route exact path='/create' component={CreateDiscussion}/>
                                    <Route exact path='/notifications' component={Notifications}/>
                                    <Route exact path='/messages' component={MessageList}/>
                                    <Route exact path='/messages/details' component={MessageConversation}/>
                                    {/*<Route exact path='/messages/:id' component={MessageConversation}/>  USE THIS WHEN TOKEN WITH ID'S ARE DONE*/}
                                    <Route exact path='/login' component={SignInSide}/>
                                    <Route exact path='/register' component={SignUp}/>
                                    <Route exact path='/discussion/:id' component={Discussion}/>
                                    <Route exact path='/settings' component={SettingsScreen}/>
                                    <Route path='/profile/:id' component={ProfilePage}/>
                                    {<Route exact path='/community/:id' component={Community}/>}
                                    <Route exact path='/newcommunity' component={CreateCommunity}/>
                                </Switch>
                            </div>
                        </Grid>
                    </Grid>
                </BrowserRouter>
            </ThemeProvider>
        </ApolloProvider>
    );
}

export default App;
