import {gql} from 'apollo-boost';

/*const getAuthorsQuery = gql`
    {
        authors{
            name
            id
        }
    }
`;

const getBooksQuery = gql`
    {
        books{
            name
            id
        }
    }
`;

const addBookMutation = gql`
    mutation($name:String!, $genre: String!, $authorId: ID!){
        addBook(name:$name, genre:$genre, authorId:$authorId){
            name
            id
        }
    }
`;*/

// CRUD TOPICS
const addTopicMutation = gql`
    mutation($user:ID!, $title:String!, $body:String!, $category:String!, $tags:[TopicTagInput]){
        addTopic(user:$user, title:$title, body:$body, category:$category, tags:$tags){
            _id
            user
            title
            body
            source
            metaImage
            category
            tags {
                _id
                name
            }
            upvotes
            main
            community
        }
    }
`;

const updateTopicMutation = gql`
    mutation($body:String, $id: ID!){
        updateTopic(body:$body, _id:$id){
            _id
            body
        }
    }
`;

const deleteTopicMutation = gql`
    mutation($_id: ID!){
        deleteTopic(_id:$_id){
            _id
        }
    }
`;

const addPendingUserOnCommunity = gql`
    mutation($user: ID!, $community: ID!, $entryMessage: String!){
        addPendingUserOnCommunity(user:$user, community:$community, entry_message:$entryMessage){
            _id
            community
            entry_message
            status
        }
    }
`;

const getTopics = gql`
    {
        getTopics{
            _id
            title
            body
            user {
                _id
                firstName
                lastName
           }
           source
           metaImage
           category {
                _id
                name
           }
           tags {
                _id
                name
           }
           givenBadges{
                _id
                user {
                    _id
                    firstName
                    lastName
                }
                comment {
                    _id
                }
                topic {
                    _id
                    title
                    body
                }
                badge {
                    _id
                    name
                }
                date_given
                status
           }
           upvotes
           nrComments
           main
           community {
                _id
                name
                description
                number_members
                image
           }
        }
    }
`;

const getTopicById = gql`
    query($id: ID!){
        getTopicById(_id: $id){
            body
            title
            source
            metaImage
            community {
                _id
                name
                number_members
            }
            upvotes
            nrComments
            givenBadges {
                _id
                badge {
                    _id
                    name
                    image
                }
            }
            user{
                _id
                firstName
                lastName
            }
            comments {
                _id
                parent
                upvotes
                body
                created_at
                edited_at
                deleted_at
                children {
                    _id
                    parent
                    upvotes
                    body
                    created_at
                    edited_at
                    deleted_at
                    children {
                        _id
                        parent
                        upvotes
                        body
                        created_at
                        edited_at
                        deleted_at
                        children {
                            _id
                            parent
                            upvotes
                            body
                            created_at
                            edited_at
                            deleted_at
                            children {
                                _id
                                parent
                                upvotes
                                body
                                created_at
                                edited_at
                                deleted_at
                            }
                        }
                    }
                }
            } 
        }
    }
`;

// CRUD COMMENT

const getComment = gql`
    {
        id
        parent
        upvotes
        body
        created_at
        edited_at
        deleted_at
        children
    }
`;

const addCommentMutation = gql`
    mutation($body:String!, $topic: ID!){
        addComment(body:$body, topic:$topic){
            topic
            body
        }
    }
`;

const updateCommentMutation = gql`
    mutation($body:String!, $topic: ID!){
        updateComment(body:$body, topic:$topic){
            topic
            parent
            upvotes
            body
            created_at
            edited_at
            deleted_at
            children
        }
    }
`;

const deleteCommentMutation = gql`
    mutation($_id: ID!){
        deleteComment(_id: $_id){
            _id
            deleted_at
        }
    }
`;

// CRUD CATEGORY

const getCategories = gql`
    {
        getCategories{
            _id
            name
            image
        }
    }
`;

const addCategoryMutation = gql`
    mutation($name:String!, $description: String!, $image: String){
        addCategory(name:$name, description:$description, image: $image){
            _id
            name
            description
            image
        }
    }
`;

const updateCategoryMutation = gql`
    mutation($_id: ID!, $name: String, $description: String, $image: String){
        updateCategory(_id: $_id, name: $name, description: $description, image: $image){
            _id
            name
            description
            image
            edited_at
        }
    }
`;

const deleteCategoryMutation = gql`
    mutation($_id: ID!){
        deleteCategory(_id: $_id){
            _id
            deleted_at
        }
    }
`;


// CRUD ROLES
const addRoleMutation = gql`
    mutation($name:String!, $description: String!){
        addRole(name:$name, description:$description){
            _id
            name
            description
        }
    }
`;

// CRUD BADGES
const addBadgeMutation = gql`
    mutation($name:String!, $description: String!, $image: String){
        addBadge(name:$name, description:$description, image: $image){
            _id
            name
            description
            image
        }
    }
`;

// CRUD KARMA
const addKarmaMutation = gql`
    mutation($comments: Int, $posts: Int){
        addKarma(comments: $comments, posts: $posts){
            _id
            comments
            posts
        }
    }
`;

// CRUD ADD GIVEN BADGE

const addGivenBadgeMutation = gql`
    mutation($user: ID!, $comment: ID, $topic: ID, $badge: ID!, $attributed_by: ID!){
        addGivenBadge(user: $user, comment: $comment, topic: $topic, badge: $badge, attributed_by: $attributed_by){
            _id
            user
            comment
            topic
            badge
            attributed_by
        }
    }
`;

// CRUD COMMUNITY

const getCommunityById = gql`
    query($id: ID!){
        getCommunityById(_id: $id){
            name
            description
            number_members
            category {
                _id
                name
            }
            image
        }
    }
`;

const checkUserOnCommunity = gql`
    query($userId: ID!, $communityId: ID!){
        checkUserOnCommunity(user: $userId, community: $communityId){
            user {
                _id
                firstName
            }
            community {
                _id
                name
            }
            status
            role {
                _id
                name
                description
            }
        }
    }
`;

const addCommunityMutation = gql`
    mutation($name: String!, $description: String!, $category: ID!, $image: String!){
        addCommunity(name: $name, description: $description, category: $category, image: $image){
            _id
            name
            description
            category{
               _id
               name
            }
            image
        }
    }
`;

export {
    /*getAuthorsQuery,
    getBooksQuery,
    addBookMutation,*/
    getTopics,
    addTopicMutation,
    updateTopicMutation,
    deleteTopicMutation,
    getTopicById,
    getComment,
    addCommentMutation,
    updateCommentMutation,
    deleteCommentMutation,
    getCategories,
    addCategoryMutation,
    updateCategoryMutation,
    deleteCategoryMutation,
    addRoleMutation,
    addKarmaMutation,
    addGivenBadgeMutation,
    getCommunityById,
    checkUserOnCommunity,
    addCommunityMutation,
    addPendingUserOnCommunity
}