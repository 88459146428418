// import React from 'react';
import {useCookies} from "react-cookie";
const jwt = require('jsonwebtoken');

function TokenDecode(){

    const [cookies] = useCookies(['cookie-name']);
    return jwt.decode(cookies.token)

}

export default TokenDecode;