import React, {useEffect, useState, useRef} from 'react';
import Paper from '@material-ui/core/Paper';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import profileImg from '../../imgs/profile.jpg';
import TextField from '@material-ui/core/TextField';
import LinkIcon from '@material-ui/icons/Link';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import {useMutation, useQuery} from "@apollo/react-hooks";
import {addTopicMutation, getCategories} from "../../queries/queries";
import TokenDecode from "../../utilComponents/TokenDecode";
import Chip from "@material-ui/core/Chip/Chip";
import LoadingComponent from "../styleComponents/LoadingComponent";
const useForceUpdate = () => useState()[1];
const axios = require('axios');

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        padding: theme.spacing(0, 3),
    },
    paper: {
        maxWidth: 400,
        padding: theme.spacing(2),
        boxShadow: 'none'
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    margin: {
        margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    addTag: {
        backgroundColor: 'white !important',
        border: '1px solid black',
        outlineColor: 'black',
        color: 'black',
        width: theme.spacing(3),
        height: theme.spacing(3),
        margin: '5px',
    },
}));

const ColorButton = withStyles(theme => ({
    root: {
        color: theme.palette.getContrastText('#D10019'),
        backgroundColor: '#D10019',
        '&:hover': {
            backgroundColor: '#D10019',
        },
    },
}))(Button);

function CreateDiscussion() {
    const classes = useStyles();
    const [category, setCategory] = useState([]);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [source, setSource] = useState('');
    const [main, setMain] = useState(false);
    const [metaImage, setMetaImage] = useState(false);
    const [community, setCommunity] = useState(false);
    const [url, setUrl] = useState("");
    const [open, setOpen] = useState(false);
    const [addTopic, {dataAdded}] = useMutation(addTopicMutation);
    const [chipData, setChipData] = useState([]);
    const [tag, setTag] = useState('');
    const fileInput = useRef(null);
    const forceUpdate = useForceUpdate();
    const [btnDisabled, setBtnDisabled] = useState(true);

    const [refresh, setRefresh] = useState(false);

    const decoded = TokenDecode();

    const {loading, error, data} = useQuery(getCategories);

    console.log(data);

    useEffect(() => {
        if (refresh) {
            setRefresh(false)
        }
    }, [refresh]);

    useEffect(() => {
        if (category && title && body && url) {
            setBtnDisabled(false)
        }
    }, [category, title, body, url]);

    const handleChange = event => {
        setCategory(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const submitForm = () => {
        let idCategory = '';
        let arrayPush = [];

        for (let i = 0; i < data.getCategories.length; i++) {
            if (category === data.getCategories[i].name) {
                idCategory = data.getCategories[i]._id
            }
        }

        for(let j = 0; j < chipData.length; j++){
            let pass = true;
            for(let k = 0; k < arrayPush.length; k++){
                if(chipData[j].name.toUpperCase() === arrayPush[k].name){
                    pass = false;
                }
            }
            if(pass) arrayPush.push(chipData[j])
        }

        addTopic({
            variables: {
                user: decoded._id,
                title,
                body,
                source,
                category: idCategory,
                tags: arrayPush,
                main,
                metaImage,
                community,
                image: url
            },
        });
    };

    const handleKeyPress = (event) => {
        console.log("EVENTO: ", event.key);
        if (event.key === 'Enter' || event.key === " " || event.which === 229 || event.key === '.' || event.key === ',') {
            event.preventDefault();
            setTag('');
            let arrayPush = chipData;
            arrayPush.push({name: event.target.value});
            setChipData(arrayPush);
        }
    };

    const addHash = (e) => {
        e.preventDefault();
        // setChipData()
        console.log('deu add ao hash');
    };

    const handleTagChange = (e) => {
        setTag(e.target.value);
    };

    const handleDelete = (e, key) => {
        console.log(e, key);
        let arrayPush = chipData;
        arrayPush.splice(key, 1);
        console.log(arrayPush);
        setChipData(arrayPush);
        setRefresh(true);
    };

    useEffect(() => {
        // console.log(title);
        // console.log(body);
        // console.log(category);
        // console.log(source);
        // console.log(tag);
        console.log(chipData);
    });

    useEffect(e => {
        window.addEventListener("keyup", clickFileInput);
        return () => window.removeEventListener("keyup", clickFileInput);
    });

    function clickFileInput(e) {
        if (fileInput.current.nextSibling.contains(document.activeElement)) {
            // Bind space to trigger clicking of the button when focused
            if (e.keyCode === 32) {
                fileInput.current.click();
            }
        }
    }

    function onSubmit(e) {
        e.preventDefault();
        const data = new FormData();
        const imagefile = document.querySelector('#imgFile');
        data.append("type", "topic");
        data.append("imgFile", imagefile.files[0]);
        axios.post('http://agora.jmfreitas.com/upload', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            setUrl(response.data.url);
        });
    }

    function fileNames() {
        const { current } = fileInput;

        if (current && current.files.length > 0) {
            let messages = [];
            for (let file of current.files) {
                messages = messages.concat(<p key={file.name}>{file.name}</p>);
            }
            return messages;
        }
        return null;
    }


    if (loading) return <LoadingComponent/>;
    return (
        <Grid className={classes.root}>
            <Grid>
                <form>
                    <Paper className={classes.paper}>
                        <Grid container wrap="nowrap" spacing={2}>
                            <Grid item>
                                <Avatar src={profileImg}></Avatar>
                            </Grid>
                            <Grid item style={{paddingTop: '1.5vh'}}>
                                <Typography variant="h5" noWrap>{decoded.firstName} {decoded.lastName} </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className={classes.paper}>
                        <TextField
                            id="outlined-textarea"
                            placeholder="Título"
                            onChange={(e) => {
                                setTitle(e.target.value)
                            }}
                            multiline
                            fullWidth
                            required
                        />
                    </Paper>
                    <Paper className={classes.paper}>
                        <TextField
                            id="outlined-textarea"
                            placeholder="Escreva sobre o assunto que pretende discutir"
                            multiline
                            onChange={(e) => {
                                setBody(e.target.value)
                            }}
                            fullWidth
                            required
                        />
                    </Paper>
                    <Paper className={classes.paper}>
                        <Typography variant="h6">Ligar a uma notícia</Typography>
                        <Typography color="textSecondary" variant="caption">Ligue a discussão a uma notícia do Público
                            relacionada com o seu comentário</Typography>
                        <div style={{display: 'flex'}}>
                            <LinkIcon color="error" style={{paddingTop: '1.5%'}}/>
                            <TextField id="outlined-textarea" fullWidth placeholder="Não obrigatório"
                                       onChange={(e) => {
                                           setSource(e.target.value)
                                       }}
                            >Escreva
                                aqui o link</TextField>
                        </div>
                    </Paper>
                    <Paper className={classes.paper}>
                        <Typography variant="h6">Publicar em</Typography>
                        <FormControl className={classes.formControl}>
                            <Select
                                labelId="demo-controlled-open-select-label"
                                id="demo-controlled-open-select"
                                open={open}
                                onClose={handleClose}
                                onOpen={handleOpen}
                                value={category}
                                onChange={handleChange}
                                required
                            >
                                {data.getCategories.map((data, key) =>
                                    (<MenuItem value={data.name} key={key}>
                                        {data.name}
                                    </MenuItem>)
                                )}
                            </Select>
                        </FormControl>
                    </Paper>
                    <Paper className={classes.paper}>
                        <FormControl margin="normal" required fullWidth>
                            <TextField
                                id="tags"
                                name="tags"
                                label="tags"
                                placeholder="tags"
                                fullWidth
                                margin="none"
                                variant="outlined"
                                value={tag}
                                onChange={(e) => {
                                    handleTagChange(e)
                                }}
                                onKeyPress={(e) => {
                                    handleKeyPress(e)
                                }}
                            />
                        </FormControl>
                    </Paper>
                    <Paper className={classes.paper}>
                        {chipData.map((data, key) => {
                                return (
                                    <Chip color="secondary" label={data.name} onDelete={(e) => handleDelete(e, key)}/>
                                )
                            }
                        )}
                    </Paper>

                    <input
                        id="imgFile"
                        type="file"
                        ref={fileInput}
                        // The onChange should trigger updates whenever
                        // the value changes?
                        // Try to select a file, then try selecting another one.
                        onChange={onSubmit}
                    />
                    {fileNames()}

                    <Paper className={classes.paper}>
                        <ColorButton variant="contained" color="primary" className="redBackgrounds" onClick={() => {
                            submitForm();
                        }} disabled={btnDisabled}>
                            Criar discussão
                        </ColorButton>
                    </Paper>
                </form>
            </Grid>
        </Grid>
    )

}

export default CreateDiscussion;