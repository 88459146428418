import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import ForumIcon from '@material-ui/icons/Forum';
import Badge from '@material-ui/core/Badge';

function BottomMenu(props) {

    const history = useHistory();
    const location = useLocation();
    const [value, setValue] = useState('');
    const [toDisplay, setToDisplay] = useState('block');

    const handleChange = (event, newValue) => {
        history.push(newValue);
        setValue(newValue);
    };

    useEffect(() => {
        setToDisplay(props.style);
    }, [props.style]);


    useEffect(() => {
        doFor();
    }, [location.pathname]);

    const doFor = () => {
        setValue(location.pathname);
    };

    history.listen((location) => {

    }, [location.pathname]);

    return (
        <div style={{display: toDisplay}}>
            <BottomNavigation value={value} onChange={handleChange} style={{
                width: '100%',
                backgroundColor: 'white',
                position: 'fixed',
                bottom: 0,
                left: 0,
                height: 60,
                zIndex: 5000,
                boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
            }}>
                <BottomNavigationAction value="/" icon={<HomeIcon/>}/>

                <BottomNavigationAction value="/search" icon={<SearchIcon/>}/>

                <BottomNavigationAction value="/create" icon={<AddCircleOutlineIcon/>}/>

                <BottomNavigationAction value="/notifications" icon={<Badge variant="dot" color="secondary"><NotificationsNoneOutlinedIcon/></Badge>}
                />
                <BottomNavigationAction value="/messages" icon={<ForumIcon/>}/>
            </BottomNavigation>
        </div>
    )
}

export default BottomMenu;