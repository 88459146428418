import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';



const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: '0px',
    },

    inline: {
        display: 'inline',
    },

    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

function Notifications() {
    const classes = useStyles();

    return (
            <List className={classes.root}>
                <ListItem style={{ alignItems: 'center', maxWidth: '100%', padding: '0.9em', backgroundColor: 'lightGray', borderBottomColor: 'lightGray',
                    borderBottomWidth: '0.1px',
                    borderBottomStyle: 'solid' }}>
                    <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                    </ListItemAvatar>
                    <Typography noWrap>John liked your comment on the discussion José Rafael será homossexual?</Typography>
                </ListItem>
                <ListItem style={{ alignItems: 'center', maxWidth: '100%', padding: '0.9em', borderBottomColor: 'lightGray',
                    borderBottomWidth: '0.1px',
                    borderBottomStyle: 'solid'}}>
                    <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                    </ListItemAvatar>
                    <Typography noWrap>John liked your comment on the discussion José Rafael será homossexual?</Typography>
                </ListItem>
                <ListItem style={{ alignItems: 'center', maxWidth: '100%', padding: '0.9em', borderBottomColor: 'lightGray',
                    borderBottomWidth: '0.1px',
                    borderBottomStyle: 'solid' }}>
                    <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src="/static/images/avatar/3.jpg" />
                    </ListItemAvatar>
                    <Typography noWrap>John liked your comment on the discussion José Rafael será homossexual?</Typography>
                </ListItem>
            </List>
    );
}

export default (Notifications);