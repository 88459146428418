import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ImgDiscuss from "../../imgs/transferir.jpg";
import {Card, CardContent} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions/CardActions";
import AvatarGroup from "@material-ui/lab/AvatarGroup/AvatarGroup";
import Avatar from "@material-ui/core/Avatar/Avatar";
import imgsTestes from "../../imgs/transferir.jpg";


const useStyles = makeStyles(theme => {
    return ({
        root: {
            display: 'flex',
            overflowY: 'hidden',
            marginTop: '2rem',


        },


        space: {

            marginTop: '1.5rem'

        },


    });
});



function ProfileCommunities() {
    const classes = useStyles();

    return (

<div>
            <Grid className={classes.root}>
            <Card className="profileCommunityCard">
                <CardContent className="profileCommunityBottomRectangle">
                    <CardActions className="profileCommunityCardActions">
                        <AvatarGroup className="profileCommunityAvatarGroup">
                            <Avatar src={imgsTestes} className={classes.small}/>
                            <Avatar src={imgsTestes} className={classes.small} />
                            <Avatar src={imgsTestes} className={classes.small} />
                        </AvatarGroup>
                    </CardActions>
                    <Typography className="redTitles profileComTitle">
                        Black Mirror Fans
                    </Typography>
                    <Typography className="communityMembers">
                        50 membros
                    </Typography>
                </CardContent>
            </Card>
            </Grid>

    <Grid className={classes.root}>
        <Card className="profileCommunityCard">
            <CardContent className="profileCommunityBottomRectangle">
                <CardActions className="profileCommunityCardActions">
                    <AvatarGroup className="profileCommunityAvatarGroup">
                        <Avatar src={imgsTestes} className={classes.small}/>
                        <Avatar src={imgsTestes} className={classes.small} />
                        <Avatar src={imgsTestes} className={classes.small} />
                    </AvatarGroup>
                </CardActions>
                <Typography className="redTitles profileComTitle">
                    Black Mirror Fans
                </Typography>
                <Typography className="communityMembers">
                    50 membros
                </Typography>
            </CardContent>
        </Card>
    </Grid>





</div>









    );
}

export default ProfileCommunities;