import React from 'react';
import ListItem from "@material-ui/core/ListItem/ListItem";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List/List";
import {makeStyles} from "@material-ui/core";
import {useCookies} from "react-cookie";
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    principal: {
        backgroundColor: '#e8e8e8',
    },

    secondary: {
        borderBottomStyle: 'solid',
        borderBottomWidth: 'thin',
        borderBottomColor: '#e8e8e8',
        height: '3rem',
        alignItems: 'center',
    },
}));

function SettingsScreen() {
    const classes = useStyles();
    const [cookies, removeCookie] = useCookies(['token']);
    const history = useHistory();

    const logout = () => {
        removeCookie("token");
        history.push("/login");
    };

    return (
        <List className={classes.root}>
            <ListItem alignItems="flex-start" className={classes.principal}>
                <Typography variant="h6">Conta</Typography>
            </ListItem>

            <ListItem alignItems="flex-start" className={classes.secondary} >
                <Typography variant="body1">Alterar palavra passe</Typography>
            </ListItem>

            <ListItem alignItems="flex-start" className={classes.secondary} >
                <Typography variant="body1">Alterar e-mail</Typography>
            </ListItem>

            <ListItem alignItems="flex-start" className={classes.secondary} >
                <Typography variant="body1">Guardados</Typography>
            </ListItem>

            <ListItem alignItems="flex-start" className={classes.principal}>
                <Typography variant="h6">Geral</Typography>
            </ListItem>

            <ListItem alignItems="flex-start" className={classes.secondary} >
                <Typography variant="body1">Política de Privacidade</Typography>
            </ListItem>

            <ListItem alignItems="flex-start" className={classes.secondary} >
                <Typography variant="body1">Sobre</Typography>
            </ListItem>

            <ListItem alignItems="flex-start" style={{marginTop: '2rem'}} button onClick={() => {logout()}}>
                <Typography variant="body1" color="primary">Terminar sessão</Typography>
            </ListItem>
        </List>
    )
}

export default SettingsScreen;
