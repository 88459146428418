import React, {useState} from 'react';
import FirstSelect from "./home/FirstSelect";
import Feed from "./home/Feed";

function MainScreen(props) {
    const [preferencesDone] = useState(true);

    return (
        <div>
            {/*<MenuBar/>*/}
            {
                !preferencesDone ? <FirstSelect/> : <Feed/>
            }
            {/*<BottomMenu/>*/}
        </div>
    );
}

export default MainScreen;