import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import {useHistory} from 'react-router-dom';
import imgsTestes from "../../imgs/profile.jpg";
import dog from "../../imgs/transferir.jpg";

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: 10,

    },

    friends: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(2),
        },
        paddingTop: 10,
        width: '100%',
    },

    inline: {
        display: 'inline',
    },

    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade("#E1E4E6", 0.25),
        },

        marginRight: theme.spacing(2),
        marginLeft: 10,
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(6),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

function MessageList() {
    const classes = useStyles();
    const history = useHistory();

        return (
            <div>
            {/*<MessagesBar/>*/}
            {/*<SecondaryMenuBar/>*/}
                <div className={classes.search} >
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </div>

                <div className={classes.friends}>
                    <Avatar alt="Remy Sharp" src={imgsTestes} className={classes.large} onClick={() => {history.push("/messages/details");}}/>
                    <Avatar alt="Travis Howard" src={imgsTestes} className={classes.large} onClick={() => {history.push("/messages/details");}} />
                    <Avatar alt="Cindy Baker" src={imgsTestes} className={classes.large} onClick={() => {history.push("/messages/details");}} />
                    <Avatar alt="Cindy Baker" src={imgsTestes} className={classes.large} onClick={() => {history.push("/messages/details");}} />
                </div>

                <List className={classes.root}>
                    <ListItem alignItems="flex-start" onClick={() => {history.push("/messages/details");}}>
                        <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={dog}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Ali Connors"
                            secondary={
                                <React.Fragment>
                                    {" I'll be in your neighborhood doing errands this…"}
                                </React.Fragment>
                            }
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start" onClick={() => {history.push("/messages/details");}}>
                        <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={dog}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Ali Connors"
                            secondary={
                                <React.Fragment>
                                    {" I'll be in your neighborhood doing errands this… I'll be in your neighborhood doing errands this, I'll be in your neighborhood doing errands this."}
                                </React.Fragment>
                            }
                        />
                    </ListItem>

                    <ListItem alignItems="flex-start" onClick={() => {history.push("/messages/details");}}>
                        <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={dog}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Ali Connors"
                            secondary={
                                <React.Fragment>
                                    {" I'll be in your neighborhood doing errands this…"}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                </List>
            </div>
        );
    }

export default (MessageList);
