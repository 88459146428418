import React from 'react';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';
import FeedLatest from "./FeedLatest";
import FeedCinema from "./FeedCinema";
import FeedCommunity from "./FeedCommunity";
import FeedMayLike from "./FeedMayLike";
import FeedHighLights from "./FeedHighLights";
import {useCookies} from 'react-cookie';

const axios = require('axios');

function Feed() {
    // const [cookies, setCookie] = useCookies(['cookie-name']);
    //
    // const verify = () => {
    //     axios.post('http://agora.jmfreitas.com/verifyToken', {
    //         data: {
    //             token: cookies.token
    //         }
    //     });
    // };

    return (
        <Grid container className="mainPadding">
            <FeedHighLights category={"Categoria"} topic={"Tópico"}/>
            <Grid container>
                <Grid item xs={12} className="feedItem">
                    <Typography variant="h6">Últimas</Typography>
                </Grid>
                <FeedLatest category={"Categoria"} topic={"Tópico"}/>
                <FeedLatest category={"Categoria"} topic={"Tópico"}/>
                <FeedCinema/>
            </Grid>
            <Grid container>
                <Grid item xs={12} className="feedItem">
                    <Typography variant="h6">Últimas</Typography>
                </Grid>
                <FeedLatest category={"Categoria"} topic={"Tópico"}/>
                <FeedLatest category={"Categoria"} topic={"Tópico"}/>
            </Grid>
            <Grid container>
                <Grid item xs={12} className="feedItem">
                    <Typography variant="h6">Comunidades</Typography>
                </Grid>
                <FeedCommunity category={"Categoria"} topic={"Tópico"}/>
            </Grid>
            <Grid container>
                <Grid item xs={12} className="feedItem">
                    <Typography variant="h6">Poderá gostar de</Typography>
                </Grid>
                <FeedMayLike category={"Categoria"} topic={"Tópico"}/>
            </Grid>
            <Grid container>
                <Grid item xs={12} className="feedItem">
                    <Typography variant="h6">Sociedade</Typography>
                </Grid>
                <FeedLatest category={"Categoria"} topic={"Tópico"}/>
                <FeedLatest category={"Categoria"} topic={"Tópico"}/>
            </Grid>
        </Grid>
    )

}

export default Feed;